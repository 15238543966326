import React from "react";

import SimpleA from '../../atoms/SimpleA';
import Consts from "../../../config/Consts";

const ShareBlock = () => {
  return (
    <div className="m-share">
      <div className="m-share-headings"><img src="/images/common/share_headings@2x.png" alt="Follow us" /></div>
      <ul className="m-share-list">
        <li className="m-share-item">
          <SimpleA className="instagram" href={Consts.LINK.SHARE_INSTAGRAM_URL} target="_blank" rel="noopener" aria-label="instagram"></SimpleA>
        </li>
        <li className="m-share-item">
          <SimpleA className="facebook" href={Consts.LINK.SHARE_FACEBOOK_URL} target="_blank" rel="noopener" aria-label="facebook"></SimpleA>
        </li>
        <li className="m-share-item">
          <SimpleA className="twitter" href={Consts.LINK.SHARE_TWITTER_URL} target="_blank" rel="noopener" aria-label="twitter"></SimpleA>
        </li>
        <li className="m-share-item">
          <SimpleA className="youtube" href={Consts.LINK.SHARE_YOUTUBE_URL} target="_blank" rel="noopener" aria-label="youtube"></SimpleA>
        </li>
        <li className="m-share-item">
          <SimpleA className="pinterest" href={Consts.LINK.SHARE_PINTEREST_URL} target="_blank" rel="noopener" aria-label="pinterest"></SimpleA>
        </li>
      </ul>
    </div>
  );
}

export default ShareBlock;