import React, { useEffect, useContext, useState, useRef } from "react";
import { useTheme } from "styled-components";

import Header from "../../molecules/Header";
import RecommendBlock from "../RecommendBlock";
import MainTemplate from "../../templates/MainTemplate";
import adid from '../../../config/adid';
import AdTop from "../../ads/AdTop";
import ArticleTop from "../../molecules/ArticleTop";
import ArticleBlock from "../ArticleBlock";
import SideBarBlock from "../SideBarBlock";
import HtmlBanner from "../../molecules/HtmlBanner";
import Nav from "../../molecules/Nav";
import AdSidebar from "../../ads/AdSidebar";
import { UserContext } from "../../../contexts/UserContext";
import Consts from "../../../config/Consts";

const Contents = ({
  article_detail,
  post_client,
  helmet,
  custom_key_topics
}) => {
  const theme = useTheme();
  const { userInfo } = useContext(UserContext);
  const [top, setTop] = useState(null);
  const [body, setBody] = useState(<ArticleBlock />);
  const setBodyData = useRef(false);

  useEffect(() => {
    if(!setBodyData.current)
    {
      const chk_article = () => {
        if(userInfo.status === 2) {
          if(userInfo.sex === 1) {
            // 男性に女性向けの記事
            if(article_detail.article_data.operation_tags.indexOf('D106') !== -1) {
              return 1
            }
          } else if(userInfo.sex === 2) {
            // 女性に男性向けの記事
            if(article_detail.article_data.operation_tags.indexOf('D105') !== -1) {
              return 1
            }
          } else {
            // その他に性別向けの記事
            if(article_detail.article_data.operation_tags.indexOf('D105') !== -1 || 
              article_detail.article_data.operation_tags.indexOf('D106') !== -1 ) {
              return 1
            }
          }
        } else if(userInfo.status !== 2) {
          // あなたへのお知らせは会員のみの記事
          if(article_detail.article_data.operation_tags.indexOf('D105') !== -1 ||
            article_detail.article_data.operation_tags.indexOf('D106') !== -1 ||
            article_detail.article_data.operation_tags.indexOf('D110') !== -1 )
          {
            return 1
          }
        }
        return 0
      }
      if(userInfo.set === 1) {
        if(chk_article() === 1) {
          window.location.href = Consts.COMMON.BASE_URL
        }
      }
      const article_top = () => {
        if(chk_article() === 1) {
          return null
        }
        return (<ArticleTop article_detail={article_detail} post_client={post_client} />)
      }
      setTop(article_top())
      const article_body = () => {
        if(chk_article() === 1) {
          return (<ArticleBlock />)
        }
        return (<ArticleBlock article_detail={article_detail} post_client={post_client} />)
      }
      setBody(article_body())
    }
  }, [userInfo])

  // operation_tagsに'D105','D106','D110'が含まれない場合は表示制御なし
  if(!setBodyData.current &&
    article_detail.article_data.operation_tags.indexOf('D105') === -1 &&
    article_detail.article_data.operation_tags.indexOf('D106') === -1 &&
    article_detail.article_data.operation_tags.indexOf('D110') === -1 )
  {
    setBodyData.current = true
    setTop(<ArticleTop article_detail={article_detail} post_client={post_client} />)
    setBody(<ArticleBlock article_detail={article_detail} post_client={post_client} />)
  }

  const custom_key_param = { topics: custom_key_topics, article_id: article_detail.article_data.article_id }
  return (
    <MainTemplate
      custom_key_param={custom_key_param}
      helmet={helmet}
      header={
        <Header post_client={post_client} />
      }
      main={
        <>
          <AdTop adid={adid.PC[theme.adpagetype].BILLBOARD} device="pc" />
          <AdTop adid={adid.SP[theme.adpagetype].BILLBOARD} device="sp" />
          <Nav topic_id={article_detail.article_data.topic_id} />
          {top}
          <section className={`p-${theme.page}__article l-article`}>
            <div className="l-container">
              <div className={`p-${theme.page}__article-wrapper l-article__wrapper`}>
                {body}
                <SideBarBlock
                  topic_id={article_detail.article_data.topic_id}
                  ad1={true}
                  ad1_sp={false}
                  ad1_sp_rectangle={false}
                  ad2_sp={true}
                  ranking={true}
                  ad3={true}
                  prarticle_first_index={1}
                  ad3_pc={true}
                  banner={true}
                />
              </div>
            </div>
          </section>
          <RecommendBlock />
          <AdSidebar adtype="RECTANGLE3" pc={false} sp={true} />
          <HtmlBanner device="sp" />
        </>
      }
    />
  )
};

export default Contents;