import React from "react";
import { useTheme } from "styled-components";

import Consts from "../../../config/Consts";
import FavoriteBtn from '../../atoms/FavoriteBtn';
import SimpleA from "../../atoms/SimpleA";

const ArticleTop = ({
  article_detail,
  post_client
}) => {
  const theme = useTheme();

  const top_image_data = article_detail.top_image_data;
  const top_image_vertical = article_detail.top_image_vertical;
  const top_sp_image_data = article_detail.top_sp_image_data;
  const article_data = article_detail.article_data;
  const credit = top_image_vertical ? top_image_vertical.credit : (top_image_data ? top_image_data.credit : '')
  const etoki = top_image_vertical ? top_image_vertical.etoki : (top_image_data ? top_image_data.etoki : '')

  const tags = article_data.topics.map((topic, index) => {
    return (
      <li key={`tag-at-${theme.page}-${index}`} className="m-tag__item"><SimpleA href={`/tag/${topic.topic_id}`} data-atlas-trackable="article_tag_upper">{`#${topic.label}`}</SimpleA></li>
    );
  });

  const tieup_flag = (article_data.operation_tags.indexOf(Consts.ID.OPERATION_TAG_TU) !== -1)
  const vertical_style = (article_data.page_number === '1' && top_image_vertical && top_image_vertical.src.length > 0) ? ' u-vertical' : ''
  const top_category = () => {
    if (!article_data.category_path) {
      return article_data.wappen;
    }
    return <a href={article_data.category_path} data-atlas-trackable="article_category">{article_data.wappen}</a>;
  }
  const sub_category = () => {
    if (!article_data.sub_category_path) {
      return article_data.sub_name;
    }
    return <a href={article_data.sub_category_path} data-atlas-trackable="article_subcategory">{article_data.sub_name}</a>;
  }
  return (
    <>
      <section className={`p-${theme.page}__top m-${theme.page}-top${vertical_style}`}>
        <div className="l-container">
          <div className={`m-${theme.page}-top__text`}>
            <p className={`m-${theme.page}-top__category`}>{top_category()}</p>
            <h1 className={`m-${theme.page}-top__headings`}>{article_data.sub_title_1}</h1>
            <h2 className={`m-${theme.page}-top__headings-h2`}>{sub_category()}</h2>
            <h3 className={`m-${theme.page}-top__headings-h3`}>{article_data.sub_title_detail}</h3>
            <div className="m-tag">
              <ul className="m-tag__list">
                {tags}
              </ul>
            </div>
            <p className={`m-${theme.page}-top__date`}>{article_data.display_time}</p>
            {tieup_flag && article_data.source.length > 0 && <p className={`m-${theme.page}-top__company`}>{`提供：${article_data.source}`}</p>}
            <FavoriteBtn post_client={post_client} article_id={article_data.article_id} is_top={true} />
          </div>
          {(article_data.page_number === '1' && (top_image_vertical || top_image_data)) && (
            <div className={`m-${theme.page}-top__image`}>
              <figure>
                {(top_image_vertical && top_image_vertical.src.length > 0) ?
                  <picture>
                    <img src={top_image_vertical.src} alt="" />
                  </picture>
                :
                  <picture>
                    <source media="(max-width: 767px)" srcSet={top_sp_image_data ? top_sp_image_data.src : ''} />
                    <img src={top_image_data ? top_image_data.src : ''} alt="" />
                  </picture>
                }
                {(credit || etoki) &&
                  <figcaption>
                    {credit &&
                      <p className="credit">{credit}</p>
                    }
                    {etoki &&
                      <p className="etoki">{etoki}</p>
                    }
                  </figcaption>
                }
              </figure>
            </div>
          )}
        </div>
      </section>
    </>
  )
};

export default ArticleTop;