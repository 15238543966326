import React, {useRef, useEffect, useState} from "react";
import { gql, useQuery } from "@apollo/client";
import { useTheme } from "styled-components";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper";

import SimpleA from '../../atoms/SimpleA';
import useScrollPosition from "../../../hooks/useScrollPosition";

export const GET_CATEGORY_LIST = gql`
  query (
    $token: ID
  ){
    category_list(
      token: $token
    ){
      label
      topic_id
      category_path
    }
  }
`

const Nav = ({
  topic_id
}) => {
  const theme = useTheme();
  const navBaseRef = useRef();
  const navRef = useRef();
  const scrollPosition = useScrollPosition();
  const activeIndex = useRef();
  // const [activeIndex, setActiveIndex] = useState(0);
  const menuSwiperRef = useRef();
  const menu_items = {pc:[], sp:[]};

  useEffect(() => {
    //日経ヘッダー分スクロール＆エリアがヘッダまできたら固定
    if (scrollPosition > 37 && navBaseRef.current.getBoundingClientRect().top <= 60) {
      //ヘッダーにis-fixedクラス付加
      navRef.current.classList.add('is-fixed');
    } else {
      //ヘッダーのis-fixedクラス削除
      navRef.current.classList.remove('is-fixed');
    }
  }, [scrollPosition]);

  const { loading, error, data } = useQuery(GET_CATEGORY_LIST, {
    variables: {
      "token": "Nav"
    }
  });
  if (loading) return null;
  if (error) return null;
  if(data.category_list.length === 0) return null;

  activeIndex.current = 1
  data.category_list.forEach((category, index) => {
    menu_items.pc.push(
      <React.Fragment key={`n-${index}`}>
        <li className={`p-${theme.page}__nav-item`}>
          <SimpleA href={`/${category.category_path}`} data-atlas-trackable={`category_${category.label.toLowerCase().replace(/\s+/g, "")}`}>{category.label}</SimpleA>
        </li>
      </React.Fragment>
    )
    let subText
    if(topic_id && topic_id === category.topic_id) {
      activeIndex.current = index
      subText = category.label
    } else {
      subText = <SimpleA href={`/${category.category_path}`} data-atlas-trackable={`category_${category.label.toLowerCase().replace(/\s+/g, "")}`}>{category.label}</SimpleA>
    }
    menu_items.sp.push(
      <SwiperSlide className={`m-menu-nav-header-item swiper-slide${topic_id ? '':' active'}`} key={`sn-${index}`}>
        {subText}
      </SwiperSlide>
    );
  });

  return (
    <>
      {/* nav */}
      <section className={`p-${theme.page}__nav`} ref={navBaseRef}>
        <div className={`p-${theme.page}__nav-menu`} ref={navRef}>
          <div className="l-container is-pc">
            <ul className={`p-${theme.page}__nav-list`}>
              {menu_items.pc}
            </ul>
          </div>
          <div className="is-sp">
            <Swiper
              slidesPerView={3}
              initialSlide={activeIndex.current}    // 
              centeredSlides={true}         // アクティブなスライドを中央に配置
              navigation={{                // paginationのクラス名
                nextEl: '.m-menu-nav-header .swiper-button-next',
                prevEl: '.m-menu-nav-header .swiper-button-prev',
              }}
              modules={[Navigation]}
              watchSlidesProgress
              className="m-menu-nav-header"
              loop={true}
              ref={menuSwiperRef}
            >
              <div className="swiper-button-prev"></div>
              <div className="swiper-button-next"></div>
              {menu_items.sp}
            </Swiper>
          </div>
        </div>
      </section>
      {/* /nav */}
    </>
  )
};

export default Nav;